import { GetAppManifestFn, EditorReadyFn } from '@wix/yoshi-flow-editor';
import { configureManifest } from './manifest';
import { getTranslateFunction } from './i18n';
import { openDashboard } from './utils/openDashboard';
import { openSettings } from './utils/openSettings';
import ActionsId from './utils/ActionsId';

let _translate: any;

export const editorReady: EditorReadyFn = async (
  editorSDK,
  token,
  { initialAppData },
) => {
  console.log('FAQ editorReady function', initialAppData.languageCode || 'en');
  _translate = await getTranslateFunction(initialAppData.languageCode || 'en');
  editorSDK.addEventListener('appActionClicked', (event) => {
    switch (event.detail.actionId) {
      case ActionsId.ADD_QUESTION:
        openDashboard(editorSDK, true);
        break;
      case ActionsId.MANAGE_FAQS:
        openDashboard(editorSDK);
        break;
      case ActionsId.CUSTOMIZE_DESIGN:
        openSettings(editorSDK);
        break;
      case ActionsId.CUSTOMIZE_DISPLAY:
        openSettings(editorSDK);
        break;
      case ActionsId.CUSTOMIZE_LAYOUT:
        openSettings(editorSDK);
        break;
      default:
        break;
    }
  });
};

export const getAppManifest: GetAppManifestFn = (
  { appManifestBuilder },
  editorSDK,
) => {
  configureManifest(appManifestBuilder, editorSDK, _translate);

  return appManifestBuilder.build();
};
