import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const openSettings = async (editorSDK: FlowEditorSDK) => {
  const FAQappId = '14c92d28-031e-7910-c9a8-a670011e062d';
  const appData = await editorSDK.document.tpa.app.getDataByAppDefId(
    '',
    FAQappId,
  );
  console.log({ appData });

  const applicationId = appData && appData.applicationId;
  console.log({ applicationId });

  const appComponents =
    applicationId &&
    (await editorSDK.document.tpa.app.getAllCompsByApplicationId(
      '',
      applicationId,
    ));
  console.log({ appComponents });

  const settingsFAQId =
    appComponents && appComponents.length && appComponents[0].id;
  console.log({ settingsFAQId });

  settingsFAQId &&
    (await editorSDK.editor.deeplink.show('', {
      type: 'component',
      params: [settingsFAQId, 'settings'],
    }));
};
