import type { FlowEditorSDK, AppManifestBuilder } from '@wix/yoshi-flow-editor';
import ActionsId from './utils/ActionsId';

export function configureManifest(
  appManifestBuilder: AppManifestBuilder,
  editorSDK: FlowEditorSDK,
  t: any,
) {
  appManifestBuilder.configureManagementActions(
    async (managementActionsBuilder) => {
      managementActionsBuilder.mainActions().addAction(
        {
          title: t('business.mainAction1'),
          icon: 'file_share_folder_open',
          actionId: ActionsId.ADD_QUESTION,
        },
        {
          title: t('business.mainAction2'),
          icon: 'file_share_folder_open',
          actionId: ActionsId.MANAGE_FAQS,
        },
      );

      managementActionsBuilder.customActions().addAction(
        {
          title: t('business.customAction1'),
          icon: 'file_share_adjustments',
          type: 'editorActions',
          actionId: ActionsId.CUSTOMIZE_DISPLAY,
        },
        {
          title: t('business.customAction2'),
          icon: 'file_share_adjustments',
          type: 'editorActions',
          actionId: ActionsId.CUSTOMIZE_LAYOUT,
        },
        {
          title: t('business.customAction3'),
          icon: 'file_share_adjustments',
          type: 'editorActions',
          actionId: ActionsId.CUSTOMIZE_DESIGN,
        },
      );

      managementActionsBuilder.learnMoreAction().set({
        id: 'b7605423-9351-4d19-9299-892ff519e75b',
      });
    },
  );
}
