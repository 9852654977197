import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';

const BASE_URL = 'https://static.parastorage.com/services/faq-platform/1.5.0';

// Initialize the i18next module (see i18next documentation for more configuration options)
const i18nextCore = (locale, cb) =>
  i18next.use(i18nextXHRBackend).init(
    {
      lng: locale,
      fallbackLng: 'en',
      keySeparator: '$',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        // Here you define where the translation files are served from
        loadPath: `${BASE_URL}/assets/locales/{{lng}}.json`,
        crossDomain: true,
      },
    },
    cb,
  );

const i18n = (locale) => {
  return new Promise((resolve, reject) => {
    const i18nInstance = i18nextCore(locale, (err, t) =>
      err ? reject(err) : resolve({ t, i18nInstance }),
    );
  });
};

export const getTranslateFunction = async (locale) => {
  const { t } = await i18n(locale);

  return t;
};
